import { Col } from "react-bootstrap";
import styled from "styled-components";

/**
 * The styled bootstrap column used as the grid square item
 */
const GridSquareBox = styled(Col)`
  overflow: hidden;

  padding: 0px;
  aspect-ratio: 1 / 1;
`;

export default GridSquareBox;
