import { AnimatePresence } from "framer-motion";
import { Speaker } from "../../../data/speakers";
import {
  SpeakerContainer,
  SpeakerImage,
  SpeakerBioContainer,
  SpeakerSocialIcon,
} from "./speaker-tile.styles";
import { useState } from "react";
import { motion } from "framer-motion";

type Props = {
  data: Speaker;
};

/**
 * A tile for the speakers page.
 */
const SpeakerTile: React.FC<Props> = ({ data }) => {
  const [detail, setDetail] = useState(false);
  const onMouseOverHandler = () => setDetail(true);
  const onMouseLeaveHandler = () => setDetail(false);

  return (
    <SpeakerContainer
      animate={{ filter: detail ? "saturate(1)" : "saturate(0)" }}
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <AnimatePresence>
        <SpeakerImage src={data.image} />
        {detail && (
          <SpeakerBioContainer
            key={`${data.name}-bio`}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.h4
              key={`${data.name}-name`}
              initial={{ x: -10, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.15 }}
            >
              {data.name}
            </motion.h4>
            <motion.p
              key={`${data.name}-role`}
              initial={{ x: -10, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.15 }}
            >
              {data.role}
            </motion.p>
            {data.linkedin && (
              <SpeakerSocialIcon type="linkedin" href={data.linkedin} />
            )}
          </SpeakerBioContainer>
        )}
      </AnimatePresence>
    </SpeakerContainer>
  );
};

export default SpeakerTile;
