import styled from "styled-components";
import Hero from "../../components/hero/hero.component";

/* Custom hero component for homepage  */
const HomeHero = styled(Hero)`
  height: 100vh;
`;

/* Textbox containing Title and Subtitle */
const HomeHeroTextBox = styled.div``;

/* Box containing the 2 buttons */
const HomeHeroButtonBox = styled.div`
  width: fit-content;
`;

export { HomeHero, HomeHeroTextBox, HomeHeroButtonBox };
