import {
  EnvelopeSimple,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import styled from "styled-components";
import { white } from "../../utils/styles";

const IconBox = styled.a``;

const iconStyled = `
  ${white}
`;

const StyledInstagram = styled(InstagramLogo)`
  ${iconStyled}
`;
const StyledFacebook = styled(FacebookLogo)`
  ${iconStyled}
`;
const StyledLinkedIn = styled(LinkedinLogo)`
  ${iconStyled}
`;
const StyledMail = styled(EnvelopeSimple)`
  ${iconStyled}
`;
const StyledYouTube = styled(YoutubeLogo)`
  ${iconStyled}
`;

export {
  IconBox,
  StyledFacebook,
  StyledInstagram,
  StyledLinkedIn,
  StyledMail,
  StyledYouTube,
};
