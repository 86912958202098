import { Plus } from "@phosphor-icons/react";
import { StartupButtonContainer } from "./startup-plus-button.styles";

type Props = {
  variant: "close" | "more";
  hover: boolean;
};

/**
 * An animated button placed at the bottom of a startup tile.
 * Included to let users know the tile is clickable.
 * The hover animation is triggered with the hover prop
 */
const StartupPlusButton: React.FC<Props> = ({ variant, hover }) => (
  <StartupButtonContainer
    animate={{
      rotate: variant === "close" ? "45deg" : "0deg",
      opacity: hover ? 1 : 0.8,
      scale: hover ? 1.3 : 1,
    }}
  >
    <Plus weight="light" color="white" size={40} />
  </StartupButtonContainer>
);

export default StartupPlusButton;
