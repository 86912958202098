import { CustomLink } from "./navbar-link.styles";
import ComponentProps from "../../utils/ComponentProps";

type Props = ComponentProps<{
  href: string;
  children?: React.ReactNode;
  active?: boolean;
}>;

const NavbarLink: React.FC<Props> = ({
  href,
  className,
  children,
  active,
  onClick,
}) => (
  <CustomLink className={className} active={active} onClick={onClick} to={href}>
    {children}
  </CustomLink>
);

export default NavbarLink;
