import React from "react";
import ComponentProps from "../../utils/ComponentProps";
import CenterLead from "../center-lead/center-lead.component";

type Props = ComponentProps<{
  children?: React.ReactNode;
  lines?: boolean;
}>;

const CenterLeadTitle: React.FC<Props> = ({ children, lines, ...props }) => (
  <CenterLead fluid={true} lines={lines} {...props}>
    <h1 className="display-4">{children}</h1>
  </CenterLead>
);

export default CenterLeadTitle;
