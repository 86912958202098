import ComponentProps from "../../utils/ComponentProps";
import React from "react";
import { AnimatedIconBox } from "./animated-down-icon.styles";
import { ArrowDown } from "@phosphor-icons/react";
type Props = ComponentProps<{}>;

/**
 * Animated down icon used in hero sections to hint about more content below
 */
const AnimatedDownIcon: React.FC<Props> = ({ ...props }) => (
  <AnimatedIconBox
    initial={{ y: -15 }}
    animate={{ y: 0 }}
    transition={{
      repeat: Infinity,
      repeatType: "mirror",
      repeatDelay: 0.2,
      duration: 0.8,
      type: "tween",
    }}
  >
    <ArrowDown size={64} weight="light" />
  </AnimatedIconBox>
);

export default AnimatedDownIcon;
