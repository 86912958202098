import styled from "styled-components";
import SocialIcon from "../../../components/social-icon/social-icon.component";

/**
 * Custom styled social icon for the team member
 */
const TeamMemberSocialIcon = styled(SocialIcon)`
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`;

const TeamMemberRole = styled.p`
  font-weight: 400;
`;

export { TeamMemberSocialIcon, TeamMemberRole };
