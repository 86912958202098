import styled from "styled-components";
import { device } from "../../utils/device";

const maxTextWidth = "800px";

/* Box cover 100 % of the width*/
interface LeadBoxProps {
  lines?: boolean;
  fluid?: boolean;
}

/* Basic style for center, the rest pass by props.style */
const LeadText = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  @media ${device.sm} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const LeadBox = styled.div<LeadBoxProps>`
  width: 100%;
  display: inline-grid;
  ${({ lines, fluid }) =>
    lines
      ? `
    grid-template-columns: auto fit-content(${
      fluid ? "100%" : maxTextWidth
    }) auto;`
      : `
      grid-template-columns: auto;
      justify-items: center;`};

  ${LeadText} {
    min-width: 300px;
    max-width: ${(props) => (props.fluid ? "100%" : maxTextWidth)};
  }
`;

const Line = styled.div`
  width: auto;
  height: 8px;
  background-color: #eda42650;
  margin-top: auto;
  margin-bottom: auto;
`;

export { LeadBox, LeadText, Line };
