import { Image } from "react-bootstrap";
import ImageBox from "./grid-image.styles";
import ComponentProps from "../../utils/ComponentProps";

type Props = ComponentProps<{
  src: string;
}>;

/**
 * A grid square containing a full-size image.
 */
const GridImage: React.FC<Props> = ({ className, src, ...props }) => {
  return (
    <ImageBox className={className} {...props}>
      <Image src={src} />
    </ImageBox>
  );
};

export default GridImage;
