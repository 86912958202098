import { Accordion } from "react-bootstrap";
import { FAQ } from "../../../data/faqs";
import FAQItem from "./faq-item/faq-item.component";
import CTASection from "../../../components/cta-section/cta-section.component";
import SectionContainer from "../../../components/section-container/section-container.component";

type Props = {
  faqs: FAQ[];
};

/**
 * Homepage FAQs Section
 */
const FAQs: React.FC<Props> = ({ faqs }) => {
  const faqItems = faqs.map((e, i) => (
    <FAQItem key={i} eventKey={`${i}`} faq={e} />
  ));
  return (
    <SectionContainer variant="light" className="px-4">
      <h1 className="display-4 pb-5">FAQs</h1>
      <Accordion flush className="w-100">
        {faqItems}
      </Accordion>
      <CTASection
        text="Still have questions?"
        btnText="Contact us"
        btnHref="/contact"
      />
    </SectionContainer>
  );
};

export default FAQs;
