import React from "react";
import ComponentProps from "../../utils/ComponentProps";
import CenterLead from "../center-lead/center-lead.component";

type Props = ComponentProps<{
  children?: React.ReactNode;
  lines?: boolean;
}>;

const CenterLeadText: React.FC<Props> = ({ children, lines, ...props }) => (
  <CenterLead lines={lines} {...props} className="lead">
    {children}
  </CenterLead>
);

export default CenterLeadText;
