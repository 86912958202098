import adhoc from "../assets/images/startups/adhoc.png";
import appcycled from "../assets/images/startups/appcycled.png";
import bloom from "../assets/images/startups/bloom.png";
import box from "../assets/images/startups/box.png";
import dtxure from "../assets/images/startups/dtxure.png";
import even from "../assets/images/startups/even.png";
import geomp from "../assets/images/startups/geomp.png";
import kerebo from "../assets/images/startups/kerebo.png";
import lokit from "../assets/images/startups/lokit.png";
import splitre from "../assets/images/startups/splitre.png";
import tastyt from "../assets/images/startups/tastyt.png";
import thrive from "../assets/images/startups/thrive.png";
import truemillennials from "../assets/images/startups/truemillennials.png";
import urbana from "../assets/images/startups/urbana.png";
import wop from "../assets/images/startups/wop.png";

import cloudzero from "../assets/images/startups/cloudzero.png";
import dropoff from "../assets/images/startups/dropoff.png";
import fooduro from "../assets/images/startups/fooduro.png";
import sidereumdigital from "../assets/images/startups/sidereumdigital.png";
import teres from "../assets/images/startups/teres.png";
import tics from "../assets/images/startups/tics.png";
import uliki from "../assets/images/startups/uliki.png";
import weply from "../assets/images/startups/weply.png";

type Startup = {
  name: string;
  category: string;
  description: string;
  image: string;
  website?: string;
};

const startups: Startup[] = [
  {
    name: "cloudzero",
    category: "Tech",
    description:
      "CLO0UDZER0 develops software for hassle-free data management across multiple cloud providers. Their focus is on providing top-level security services while enabling companies to leverage different cloud offerings based on their evolving data needs.",
    image: cloudzero,
  },
  {
    name: "dropoff",
    category: "Mobility",
    description:
      "Drop off is a mobile app that lets users book a personal valet to pick up and park their vehicle at a chosen time and location. It provides a convenient and worry-free experience for users, ensuring safe parking and a seamless return process.",
    image: dropoff,
    website: "https://drop-off.it/",
  },

  {
    name: "fooduro",
    category: "Foodtech",
    description:
      "Fooduro aims to revolutionalize the food delivery sector through sustainable, reusable packaging solutions. By promoting sustainability and adopting a circular approach, Fooduro is paving the way for a more responsible and eco-conscious future in the industry.",
    image: fooduro,
    website: "mailto:info@fooduro.eu",
  },

  {
    name: "sidereumdigital",
    category: "Marketing",
    description:
      "Sidereum offers specialized financial digital marketing services for finance boutiques. Their tailored approach helps clients reach their audience, enhance their reputation, and effectively promote content on preferred social media platforms.",
    image: sidereumdigital,
  },

  {
    name: "teres",
    category: "Fashion",
    description:
      "Teres is developing a circular economy value chain that converts supermarket fruit scraps into eco-friendly cotton using a chemical process. The cotton can be used as a raw material for fabric and clothing production.",
    image: teres,
    website: "https://teresfibers.wordpress.com/",
  },

  {
    name: "tics",
    category: "Social Network",
    description:
      "Tics is the first online community designed to provide education on political topics to the Gen-Z.",
    image: tics,
  },

  {
    name: "uliki",
    category: "Foodtech",
    description:
      "ULIKI is an innovative platform that simplifies meal planning and grocery shopping. It offers personalized meal recommendations, accurate grocery lists with real prices from selected supermarkets, and a stress-free experience for enjoying delicious meals.",
    image: uliki,
  },

  {
    name: "weply",
    category: "SportTech",
    description:
      "Weply is the social-marketplace for second hand sporting goods. It enables sports enthusiasts to buy and sell equipment while playing a key role in preserving our planet.",
    image: weply,
    website: "https://www.weply.it/",
  },
  {
    name: "adhoc",
    category: "Consumer",
    description:
      "We're a 100% Made in Italy platform offering exclusive clothing collections, accessories and jewellery, made with hearth by local artisans. We foster authenticity and quality in fashion: Buy Less, Buy Better!",
    image: adhoc,
    website: "https://adhocatelier.it/",
  },
  {
    name: "appcycled",
    category: "Consumer",
    description:
      "Appcycled is the digital platform that simplifies the process of upcycling, merging creative artists and designers in one place, with the aim of reducing textile waste. A marketplace for unique, fashionable and sustainable items.",
    image: appcycled,
    website: "https://www.upcycleyourwardrobe.com/",
  },
  {
    name: "bloom",
    category: "Tech",
    description:
      "BLOOM optimizes urban delivery processes by utilizing deep tech potential and micro-mobility through a community of cycling operators, aiming to establish an eco-sustainable supply chain model for the future.",
    image: bloom,
  },
  {
    name: "box",
    category: "Tech",
    description:
      "Box is a car streaming service that provides a virtual garage for users to select and replace cars. With a fixed subscription covering all expenses, customers can drive without worries and choose their preferred driving experience.",
    image: box,
    website: "https://www.dootbox.it/",
  },
  {
    name: "dtxure",
    category: "Biotech",
    description:
      "Software is the new molecule: Digital Therapeutics enable a new kind of medicine in which patients are in control of their progress and their own health. DTxURE provides support to companies and people that innovate in the DTx environment. We bridge the gap between your therapy and its patients.",
    image: dtxure,
  },
  {
    name: "even",
    category: "SaaS",
    description:
      "We Provide a new automated way to manage vending machines around the world.",
    image: even,
  },
  {
    name: "geomp",
    category: "Space",
    description:
      "GeoMP è una realtà che fornisce servizi nel campo dei dati satellitari e geospaziali per il monitoraggio della terra. Assistiamo e connettiamo users ed experts in questo settore per rendere l'utilizzo di questo tipo di dati il più semplice possibile",
    image: geomp,
    website: "https://www.geomp.eu/",
  },
  {
    name: "kerebo",
    category: "SaaS",
    description:
      "Kerebo is a platform that connects work-spaces owners with professional figures that need to rent a place to work on the short term.",
    image: kerebo,
  },
  {
    name: "lokit",
    category: "Mobility",
    description:
      "Lokit is building a universal infrastructure for parking and charging electric scooters in Italy using technologies like IoT, Blockchain, and AI. Lokit aims to combat vandalism, restore order in cities, and promote sustainable mobility services for smart cities.",
    image: lokit,
    website: "https://www.lokit.it/",
  },
  {
    name: "splitre",
    category: "Consumer",
    description:
      "An alternative to the common expensive investment vehicles, SPLIT is a blockchain-based platform in which shares of rented properties can be listed, bought and sold, allowing individuals to overcome capital requirements and invest in real-estate from as little as 1€.",
    image: splitre,
    website: "https://split.re/",
  },
  {
    name: "tastyt",
    category: "Consumer",
    description:
      "Tastyt wants to reinvent food delivery experience by building a customer-centric, vertically integrated tech ghost kitchen chain.",
    image: tastyt,
    website: "https://tastyt.it/",
  },
  {
    name: "thrive",
    category: "EdTech",
    description:
      "Thrive aims to foster an innovative, efficient and merit-based recruitment experience providing our clients with the opportunity to challenge candidates in practical projects and allowing students to express themselves through teamwork.",
    image: thrive,
    website: "https://thrive-world.com/",
  },
  {
    name: "truemillennials",
    category: "EdTech",
    description:
      "Truemillennials simplifies students' career choices through an experience sharing community. They create video content, connect students with professionals, and emphasize the values of future, simplicity, sharing, and awareness.",
    image: truemillennials,
    website: "https://lnk.bio/truemillennials",
  },
  {
    name: "urbana",
    category: "AgTech",
    description:
      "Urbana aims to urbanise agri-food production to ensure superior quality products in compliance with the principles of sustainability.",
    image: urbana,
  },
  {
    name: "wop",
    category: "Consumer",
    description:
      "WoP is a two-sided platform which, on one hand, wants to offer an accessible, customized and high-quality photography service, while on the other hand, aims to value the work of young photographers, giving them the opportunity to reach a larger scope of clients easily.",
    image: wop,
  },
];

export default startups;
export type { Startup };
