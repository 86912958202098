import { useNavigate } from "react-router-dom";
import ComponentProps from "../../utils/ComponentProps";
import CustomButton, {
  ButtonProps,
} from "../custom-button/custom-button.component";

type Props = ComponentProps<
  ButtonProps & {
    href: string;
  }
>;

/**
 * clickable button with react-router navigate support.
 * used when a button needs to navigate to a route (as a react-router Link)
 */
const ButtonLink: React.FC<Props> = ({ href, children, ...props }) => {
  const navigate = useNavigate();
  const onClickHandler = href.startsWith("http")
    ? () => (window.location.href = href)
    : () => navigate(href);

  return (
    <CustomButton {...props} onClick={onClickHandler}>
      {children}
    </CustomButton>
  );
};

export default ButtonLink;
