import styled from "styled-components";
import GridSquare from "../../../components/grid-square/grid-square.component";
import { motion } from "framer-motion";
import { white } from "../../../utils/styles";

/**
 * Base styles for a startup tile
 */
const BaseTile = styled(GridSquare)`
  text-align: center;
`;
/**
 * Startup tile color variants
 */

const StartupTile1 = styled(BaseTile)`
  background-color: #212428;
`;

const StartupTile2 = styled(BaseTile)`
  background-color: #d98829;
`;

const StartupTile3 = styled(BaseTile)`
  background-color: #505862;
`;

const StartupTile4 = styled(BaseTile)`
  background-color: #c46900;
`;

/**
 * Container for the startup logo. Defines the sizing of the logo
 */
const StartupImageContainer = styled(motion.div)`
  height: 40%;
  width: 65%;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`;

/**
 * Main container for the startup logo and information
 */
const StartupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0px;
  position: relative;
`;

/**
 * Startup additional information
 */
const StartupInfo = styled(motion.p)`
  padding-top: 20px;
  padding-bottom: 68px;
  width: 90%;
  ${white}
`;

/**
 * Link to the startup website
 */
const StartupLink = styled.a`
  ${white}
  text-transform: uppercase;
`;

/**
 * Top indication on the startup category
 */
const StartupCategory = styled(motion.p)`
  ${white}
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: right;
  text-transform: uppercase;
  padding: 15px;
`;

export {
  StartupTile1,
  StartupTile2,
  StartupTile3,
  StartupTile4,
  StartupImageContainer,
  StartupContainer,
  StartupLink,
  StartupInfo,
  StartupCategory,
};
