import { Partner } from "../../../../data/partners";
import { CustomImage } from "./partner-image.styles";

type Props = {
  data: Partner;
  variant?: "lg" | "md" | "sm";
};

/**
 * A partner image, with an optional link to the partner's website.
 * The variant prop allows to resize the element.
 */
const PartnerImage: React.FC<Props> = ({ data, variant = "md" }) => {
  return (
    <div className="px-5 py-4 text-center">
      <a href={data.href}>
        <CustomImage variant={variant} src={data.image} alt={data.name} />
      </a>
    </div>
  );
};

export default PartnerImage;
