import { Col, Row } from "react-bootstrap";
import { TeamMemberSocialIcon, TeamMemberRole } from "./team-member.styles";
import { Member } from "../../../data/team";
import GridDoubleSquare from "../../../components/grid-double-square/grid-double-square.component";

type Props = {
  data: Member;
  position: "left" | "right";
};

/**
 * A team member for the about page
 */
const TeamMember: React.FC<Props> = ({ data, position }) => (
  <GridDoubleSquare
    image={data.image}
    position={position}
    contentOptions={{
      backgroundColor: "#fabf73",
      hoverBackgroundColor: "#fabf73",
    }}
  >
    <Row className="align-items-center justify-content-center w-100 h-100 m-0">
      <Col>
        <h3>{data.name}</h3>
        <TeamMemberRole>{data.role}</TeamMemberRole>
        <p>{data.description}</p>
        <hr></hr>
        <p>
          {data.facebook && (
            <TeamMemberSocialIcon type="facebook" href={data.facebook} />
          )}
          {data.instagram && (
            <TeamMemberSocialIcon type="instagram" href={data.instagram} />
          )}
          {data.linkedin && (
            <TeamMemberSocialIcon type="linkedin" href={data.linkedin} />
          )}
        </p>
      </Col>
    </Row>
  </GridDoubleSquare>
);

export default TeamMember;
