import { Icon } from "@phosphor-icons/react";
import ComponentProps from "../../../../utils/ComponentProps";
import { LookingForTileContainer } from "./looking-for-tile.styles";

type Props = ComponentProps<{
  data: {
    title: string;
    description: string;
    icon: Icon;
  };
  position: "left" | "right";
}>;

const LookingForTile: React.FC<Props> = ({ data, position }) => (
  <LookingForTileContainer position={position}>
    <data.icon weight="light" size={56}></data.icon>
    <h3>{data.title}</h3>
    <p>{data.description}</p>
  </LookingForTileContainer>
);

export default LookingForTile;
