import ComponentProps from "../../utils/ComponentProps";
import CenterLeadText from "../center-lead-text/center-lead-text.component";
import CenterLeadTitle from "../center-lead-title/center-lead-title.component";
import { SectionLeadContainer } from "./section-lead.styles";

type Props = ComponentProps<{
  title?: React.ReactNode;
  text?: React.ReactNode;
}>;

/**
 * The Lead for a page section. Includes a title and lead paragraph.
 */
const SectionLead: React.FC<Props> = ({ title, text }) => (
  <SectionLeadContainer>
    {title && (
      <CenterLeadTitle lines={false} className="mb-5">
        {title}
      </CenterLeadTitle>
    )}
    {text && <CenterLeadText lines={false}>{text}</CenterLeadText>}
  </SectionLeadContainer>
);

export default SectionLead;
