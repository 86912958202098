import CTASection from "../../components/cta-section/cta-section.component";
import GridContainer from "../../components/grid-container/grid-container.component";
import HeroTitle from "../../components/hero-title/hero-title.component";
import HighlightedText from "../../components/highlighted-text/highlighted-text.component";
import SectionContainer from "../../components/section-container/section-container.component";

import startups from "../../data/startups";
import StartupTile from "./startup-tile/startup-tile.component";
import TitleSection from "../../components/title-section/title-section.component";
import SectionLead from "../../components/section-lead/section-lead.component";
import StatsRow from "../../components/stats-row/stats-row.component";

import imgHero from "../../assets/images/heros/startups.jpg";

function Startups() {
  const startupTiles = startups.map((e, i) => (
    <StartupTile key={i} data={e} variant={(i % 4) + 1} />
  ));

  return (
    <div>
      <HeroTitle imgSrc={imgHero}>
        Our
        <br />
        Startups.
      </HeroTitle>
      <TitleSection
        text={
          <>
            During Astra Incubator&apos;s program participants receive support
            to build
            <HighlightedText>
              {" "}
              successful and inspiring startups
            </HighlightedText>
            , which shape the future of business and innovation.
          </>
        }
      />
      <SectionContainer variant="light">
        <SectionLead title="We support student entrepreneurs before anyone else." />
        <StatsRow
          stat1={{ number: 35, postNumber: "+", text: "incubated startups" }}
          stat2={{
            number: 100,
            postNumber: "+",
            text: "involved participants",
          }}
          stat3={{
            number: 1,
            preNumber: "€",
            postNumber: "M+",
            text: "raised by our teams",
          }}
        />
        <GridContainer xs={1} md={2} lg={3}>
          {startupTiles}
        </GridContainer>
      </SectionContainer>
      <CTASection
        text="Are you a startupper? Pre-apply to Astra today."
        btnText="Contact us"
        btnHref="/contact"
      />
    </div>
  );
}

export default Startups;
