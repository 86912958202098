import styled from "styled-components";
import GridImage from "../../../components/grid-image/grid-image.component";
import { motion } from "framer-motion";
import SocialIcon from "../../../components/social-icon/social-icon.component";

/**
 * Custom styled social icon for the speaker
 */
const SpeakerSocialIcon = styled(SocialIcon)`
  width: 24px;
  color: rgba(255, 255, 255, 0.6);

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

/**
 * Custom Styled image for the speaker tile
 */
const SpeakerImage = styled(GridImage)``;

/**
 * Overlay bio container for a speaker
 */
const SpeakerBioContainer = styled(motion.div)`
  position: absolute;
  bottom: 0px;

  width: 100%;
  height: 100%;
  padding: 7px;

  display: flex;
  flex-direction: column;
  justify-content: end;
  backdrop-filter: brightness(60%);
`;

/**
 * Main container box for the speaker tile
 */
const SpeakerContainer = styled(motion.div)`
  position: relative;
`;

export {
  SpeakerImage,
  SpeakerBioContainer,
  SpeakerContainer,
  SpeakerSocialIcon,
};
