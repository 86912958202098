import { motion } from "framer-motion";
import styled from "styled-components";

/**
 * Box for the animated icon
 */
const AnimatedIconBox = styled(motion.div)`
  position: absolute;
  bottom: 40px;
`;

export { AnimatedIconBox };
