import React from "react";
import { Week } from "../../../../data/timeline";
import { TimelineTileBox, TimelineWeek } from "./timeline-tile.styles";
import IconBoxPrimary from "../../../../components/icon-box-primary/icon-box-primary.component";

type Props = {
  data: Week;
};

const TimelineTile: React.FC<Props> = ({ data }) => (
  <TimelineTileBox>
    <TimelineWeek>Week #{data.num}</TimelineWeek>
    <IconBoxPrimary>
      <data.icon weight="duotone" size={48}></data.icon>
    </IconBoxPrimary>
    <h5>{data.title}</h5>
  </TimelineTileBox>
);

export default TimelineTile;
