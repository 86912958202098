import styled from "styled-components";

const FeatureTitle = styled.h3`
  margin-top: 40px;
`;

const FeatureText = styled.p`
  margin-top: 30px;
  margin-bottom: 100px;
  font-size: 110%;
`;

const FeatureElementContainer = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
`;

const FeatureLink = styled.a`
  color: unset;
`;

export { FeatureElementContainer, FeatureTitle, FeatureText, FeatureLink };
