import ComponentProps from "../../utils/ComponentProps";
import {
  SectionBaseContainer,
  SectionContainerDark,
  SectionContainerLight,
  SectionContainerPrimary,
} from "./section-container.styles";

function getContainer(variant: string) {
  switch (variant) {
    case "primary":
      return SectionContainerPrimary;
    case "light":
      return SectionContainerLight;
    case "dark":
      return SectionContainerDark;
    default:
      return SectionBaseContainer;
  }
}

type Props = ComponentProps<{
  children?: React.ReactNode;
  variant?: "primary" | "light" | "dark" | "none";
  fluid?: "horizontal" | "vertical" | "all";
}>;

/**
 * A section of a webpage.
 * Provides a maximum content width and optional padding (fluid prop).
 * The content is centered.
 */
const SectionContainer: React.FC<Props> = ({
  children,
  fluid,
  variant = "none",
  ...props
}) => {
  const Container = getContainer(variant);
  return (
    <Container fluid={fluid} {...props}>
      {children}
    </Container>
  );
};

export default SectionContainer;
