import styled from "styled-components";
import GridImage from "../grid-image/grid-image.component";
import GridSquare from "../grid-square/grid-square.component";

type ContentBoxProps = {
  $position: "left" | "right";
  $hover: boolean;
  $backgroundColor?: string;
  $hoverBackgroundColor?: string;
};

/**
 * Container for the content, with changing background color when the component is hovered
 */
const ContentBox = styled(GridSquare)<ContentBoxProps>`
  ${(props) => `
  background-color: ${
    props.$hover ? props.$hoverBackgroundColor : props.$backgroundColor
  };
  text-align: ${props.$position};
  transition: background-color ease-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  `}
`;

type AnimatedGridImageProps = {
  $hover: boolean;
};

/**
 * Animated grid image. The image zooms and colors when the component is hovered
 */
const AnimatedGridImage = styled(GridImage)<AnimatedGridImageProps>`
  img {
    filter: grayscale(${(props) => (props.$hover ? "0" : "1")});
    transform: scale(${(props) => (props.$hover ? "1.1" : "1")});
    transition: filter ease-out 0.3s, transform ease-out 0.3s;
  }
`;

export { ContentBox, AnimatedGridImage };
