import { Accordion } from "react-bootstrap";
import { FAQ } from "../../../../data/faqs";

type Props = {
  eventKey: string;
  faq: FAQ;
};

/**
 * A FAQ Item, rendered as an accordion item
 */
const FAQItem: React.FC<Props> = ({ eventKey, faq }) => (
  <Accordion.Item eventKey={eventKey}>
    <Accordion.Header>{faq.question}</Accordion.Header>
    <Accordion.Body>{faq.answer}</Accordion.Body>
  </Accordion.Item>
);

export default FAQItem;
