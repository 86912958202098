import CTASection from "../../components/cta-section/cta-section.component";
import GridContainer from "../../components/grid-container/grid-container.component";
import HeroTitle from "../../components/hero-title/hero-title.component";
import HighlightedText from "../../components/highlighted-text/highlighted-text.component";
import SectionContainer from "../../components/section-container/section-container.component";
import SectionLead from "../../components/section-lead/section-lead.component";
import TitleSection from "../../components/title-section/title-section.component";
import speakers from "../../data/speakers";
import SpeakerTile from "./speaker-tile/speaker-tile.component";

import imgHero from "../../assets/images/heros/speakers.jpg";
function Speakers() {
  const speakerTiles = speakers.map((e) => (
    <SpeakerTile key={e.name} data={e} />
  ));

  return (
    <div>
      <HeroTitle imgSrc={imgHero}>
        Our
        <br />
        Speakers & Mentors.
      </HeroTitle>
      <TitleSection
        text={
          <>
            <HighlightedText>
              People and networking are essential
            </HighlightedText>{" "}
            for a great idea&apos;s success.
            <br />
            In our program we give participants the chance to listen to{" "}
            <HighlightedText>exclusive talks</HighlightedText>
            and be mentored by the most relevant{" "}
            <HighlightedText>italian startup professionals</HighlightedText>.
          </>
        }
      />
      <SectionContainer variant="dark">
        <SectionLead title="We have a stellar board of speakers and mentors." />
        <GridContainer xs={2} md={4} className="pt-5">
          {speakerTiles}
        </GridContainer>
      </SectionContainer>
      <CTASection
        text="Wanna become a mentor? Join our pool of professionals and help us shape the future of the Italian Startup Ecosystem"
        btnText="Work with us"
        btnHref="/contact"
      />
    </div>
  );
}

export default Speakers;
