import { Announcement } from "../../data/announcements";
import ComponentProps from "../../utils/ComponentProps";
import ButtonLink from "../button-link/button-link.component";
import {
  BannerBase,
  BannerDark,
  BannerLight,
  BannerPrimary,
} from "./banner.styles";

type Props = ComponentProps<{
  data: Announcement;
  variant?: "primary" | "light" | "dark";
}>;

function getComponent(variant?: string) {
  switch (variant) {
    case "primary":
      return BannerPrimary;
    case "light":
      return BannerLight;
    case "dark":
      return BannerDark;
    default:
      return BannerBase;
  }
}

function getButtonVariant(variant?: string) {
  switch (variant) {
    case "primary":
      return "light";
    case "light":
      return "dark";
    case "dark":
      return "light";
    default:
      return "primary";
  }
}

const Banner: React.FC<Props> = ({ data, variant }) => {
  const Component = getComponent(variant);
  const buttonVariant = getButtonVariant(variant);
  return (
    <Component>
      <b>{data.text}</b>
      {data.cta && (
        <ButtonLink variant={buttonVariant} size="sm" href={data.cta.href}>
          {data.cta.text}
        </ButtonLink>
      )}
    </Component>
  );
};

export default Banner;
