import { CSSProperties } from "react";
import { LeadBox, LeadText, Line } from "./center-lead.styles";

type Props = {
  style?: CSSProperties;
  children?: React.ReactNode;
  className?: string;
  lines?: boolean;
  fluid?: boolean;
};

/**
 * A centered piece of text
 * Optionally, two lines can be placed on the sides of the text with the "lines" param
 * Optionally, the max width limit can be removed with the fluid prop
 */
const CenterLead: React.FC<Props> = ({
  style,
  children,
  className,
  lines,
  fluid,
}) => (
  <LeadBox fluid={fluid} lines={lines} style={style} className={className}>
    {lines && <Line />}
    <LeadText>{children}</LeadText>
    {lines && <Line />}
  </LeadBox>
);

export default CenterLead;
