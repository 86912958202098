import styled from "styled-components";
import { uppercase } from "../../../../utils/styles";
import { device } from "../../../../utils/device";

const TimelineTileBox = styled.div`
  border: 1px solid #00000010;
  padding-top: 50px;
  padding-bottom: 50px;

  padding-left: 50px;
  padding-right: 50px;
  @media${device.lg} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media${device.xxl} {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const TimelineWeek = styled.p`
  ${uppercase}
  font-weight: 300;
`;

export { TimelineTileBox, TimelineWeek };
