import HeroTitle from "../../components/hero-title/hero-title.component";
import TeamMember from "./team-member/team-member.component";
import { useMediaQuery } from "react-responsive";
import { device } from "../../utils/device";
import team from "../../data/team";
import CTASection from "../../components/cta-section/cta-section.component";
import SectionContainer from "../../components/section-container/section-container.component";
import GridContainer from "../../components/grid-container/grid-container.component";
import TitleSection from "../../components/title-section/title-section.component";

import imgHero from "../../assets/images/heros/about.jpg";

type Props = {};

const About: React.FC<Props> = () => {
  const md = useMediaQuery({ query: device.md });
  const teamComponents = team.map((e: any, i: number) => (
    <TeamMember
      position={i % 2 === 1 && md ? "left" : "right"}
      data={e}
      key={i}
    />
  ));

  return (
    <div>
      <HeroTitle imgSrc={imgHero}>
        Our
        <br />
        Team.
      </HeroTitle>
      <TitleSection
        text={
          <>
            We are a passionate team of students, developers, business coaches.{" "}
            <br />
            Our goal is to foster innovation and leverage the Italian startup
            ecosystem to develop the next generation of startuppers and
            entrepreneurs.
          </>
        }
      />
      <SectionContainer fluid="vertical" variant="light">
        <GridContainer xs={1} md={2}>
          {teamComponents}
        </GridContainer>
      </SectionContainer>
      <CTASection
        text="Wanna be part of the team? Volunteer with Astra and help us shape the future of Italian entrepreneurship."
        btnText="Join us"
        btnHref="https://forms.gle/xfxpeF7eX7A7hWt18"
      />
    </div>
  );
};

export default About;
