import { Row } from "react-bootstrap";
import ComponentProps from "../../utils/ComponentProps";

type Props = ComponentProps<{
  children?: React.ReactNode;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}>;

/**
 * Container for the custom square grid system.
 * Allows specifying the number of grid elements to be placed in a row for a set of width breakpoints.
 */
const GridContainer: React.FC<Props> = ({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  ...props
}) => (
  <Row
    {...{ xs, sm, md, lg, xl, xxl, ...props }}
    className={`gx-0 ${props.className}`}
  >
    {children}
  </Row>
);

export default GridContainer;
