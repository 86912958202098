import {
  Coins,
  Flask,
  Handshake,
  HourglassHigh,
  Icon,
  Lightbulb,
  ListMagnifyingGlass,
  PencilSimple,
  PresentationChart,
  Target,
  Trophy,
} from "@phosphor-icons/react";

type Week = {
  num: number;
  title: string;
  icon: Icon;
};

const timeline: Week[] = [
  { num: 1, title: "Intro to entrepreneurship", icon: Lightbulb },
  {
    num: 2,
    title: "Problem Finding & Data Gathering",
    icon: ListMagnifyingGlass,
  },
  { num: 3, title: "Design your MVP", icon: PencilSimple },
  { num: 4, title: "Build and Test your MVP", icon: Flask },
  { num: 5, title: "Go To Market", icon: Target },
  { num: 6, title: "Unit Economics & Legal", icon: Handshake },
  { num: 7, title: "Fundraising", icon: Coins },
  { num: 8, title: "Pitching", icon: PresentationChart },
  { num: 9, title: "Showcase Prep", icon: HourglassHigh },
  { num: 10, title: "Showcase Day", icon: Trophy },
];

export default timeline;
export type { Week };
