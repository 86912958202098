import React from "react";
import { Week } from "../../../data/timeline";
import TimelineTile from "./timeline-tile/timeline-tile.component";
import GridContainer from "../../../components/grid-container/grid-container.component";
import ComponentProps from "../../../utils/ComponentProps";
import SectionLead from "../../../components/section-lead/section-lead.component";

type Props = ComponentProps<{
  timeline: Week[];
}>;

const Timeline: React.FC<Props> = ({ timeline, ...props }) => {
  const timelineElements = timeline.map((e) => <TimelineTile data={e} />);

  return (
    <div {...props} style={{ marginBottom: 200 }}>
      <SectionLead
        title="We guide you in your entrepreneurial journey."
        text="Our 10-week incubation Program will provide you with the most
          practical approach and insights from successful founders, investors
          and professionals."
      />
      <GridContainer xs={1} sm={2} md={3} lg={4} xl={5}>
        {timelineElements}
      </GridContainer>
    </div>
  );
};

export default Timeline;
