import { Outlet } from "react-router-dom";
import Footer from "../../components/footer/footer.component";
import Navbar from "../../components/navbar/navbar.component";
import announcements from "../../data/announcements";
import Banner from "../../components/banner/banner.component";
import { NavigationContainer } from "./navigation.styles";

function Navigation() {
  const banners = announcements.map((e, i) => (
    <Banner key={i} data={e} variant="primary" />
  ));

  return (
    <>
      {banners}
      <NavigationContainer>
        <Navbar />
        <Outlet />
        <Footer />
      </NavigationContainer>
    </>
  );
}

export default Navigation;
