import styled from "styled-components";
import GridSquare from "../grid-square/grid-square.component";

/**
 * Custom grid square as an image container
 */
const ImageBox = styled(GridSquare)`
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
`;

export default ImageBox;
