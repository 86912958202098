import ComponentProps from "../../utils/ComponentProps";
import CenterLeadText from "../center-lead-text/center-lead-text.component";
import CenterLeadTitle from "../center-lead-title/center-lead-title.component";
import SectionContainer from "../section-container/section-container.component";

type Props = ComponentProps<{
  title?: React.ReactNode;
  text?: React.ReactNode;
}>;

/**
 * A section with a title and a description.
 */
const TitleSection: React.FC<Props> = ({ title, text, ...otherProps }) => (
  <SectionContainer fluid="horizontal" {...otherProps}>
    {title && (
      <CenterLeadTitle lines={true} className="pb-5">
        {title}
      </CenterLeadTitle>
    )}
    {text && <CenterLeadText lines={!title}>{text}</CenterLeadText>}
  </SectionContainer>
);

export default TitleSection;
