import React, { useState } from "react";
import ComponentProps from "../../utils/ComponentProps";
import { AnimatedGridImage, ContentBox } from "./grid-double-square.styles";

type Props = ComponentProps<{
  position: "left" | "right";
  children?: React.ReactNode;
  image: string;
  contentOptions?: {
    backgroundColor?: string;
    hoverBackgroundColor?: string;
  };
}>;

/**
 * A pair of grid squares, with hover animation.
 * A grid square displays an image, while the other one displays some content.
 * The position of the content square can be controlled with the position prop.
 * Children corresponds to the content displayed in the second square
 */
const GridDoubleSquare: React.FC<Props> = ({
  position,
  children,
  image,
  contentOptions,
}) => {
  const [hover, setHover] = useState(false);

  const onMouseOverHandler = () => setHover(true);
  const onMouseLeaveHandler = () => setHover(false);

  const content = (
    <ContentBox
      $position={position}
      $hover={hover}
      className={`p-4`}
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseLeaveHandler}
      $backgroundColor={contentOptions?.backgroundColor}
      $hoverBackgroundColor={contentOptions?.hoverBackgroundColor}
    >
      {children}
    </ContentBox>
  );

  return (
    <>
      {position === "left" && content}
      <AnimatedGridImage
        $hover={hover}
        src={image}
        onMouseOver={onMouseOverHandler}
        onMouseLeave={onMouseLeaveHandler}
      ></AnimatedGridImage>
      {position === "right" && content}
    </>
  );
};

export default GridDoubleSquare;
