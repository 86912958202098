import speaker from "../assets/images/services/speaker.jpg";
import coach from "../assets/images/services/coach.jpg";
import mentor from "../assets/images/services/mentor.jpg";
import dev_design from "../assets/images/services/dev_design.jpg";
import matching from "../assets/images/services/matching.jpg";

type Service = {
  name: string;
  description: string;
  image: string;
};

const services: Service[] = [
  {
    name: "Find your Co-Founder",
    description:
      "Our cutting-edge matching engine connects you with aspiring co-founders based on skills, experience, and interests. We analyze applicants profiles to suggest personalized co-founder matches, fostering successful partnerships in your entrepreneurial journey.",
    image: matching,
  },
  {
    name: "Industry Expert Speakers",
    description:
      "Each week there will be a different topic, and online content from prominent speakers from the industry will guide you during the launch of your startup.",
    image: speaker,
  },
  {
    name: "Dedicated coaches",
    description:
      "Every startup is paired with a coach with relevant experience who will work side by side on the development of the idea.",
    image: coach,
  },
  {
    name: "Unmatched network of international mentors",
    description:
      "We don’t pretend to know everything or have all the solutions. We leverage our experience in Silicon Valley to let you sit with people with expertise in your vertical to fast track your development.",
    image: mentor,
  },
  {
    name: "In-house Web Development and Design team",
    description:
      "We like to get our hands dirty. Whether you are working on your logo or having trouble with your backend, we support you on that. ",
    image: dev_design,
  },
];

export default services;
export type { Service };
