import { Button, Col, Form, Row } from "react-bootstrap";
import imgHero from "../../assets/images/heros/speakers.jpg";
import HeroTitle from "../../components/hero-title/hero-title.component";
import SectionContainer from "../../components/section-container/section-container.component";
import TitleSection from "../../components/title-section/title-section.component";

const Newsletter: React.FC = () => {
  return (
    <div>
      <HeroTitle imgSrc={imgHero}>
        Our
        <br />
        Newsletter.
      </HeroTitle>
      <TitleSection
        className="pb-0"
        text={
          <>
            Stay informed and connected by subscribing to our newsletter.
            Receive exclusive content and updates from Astra directly in your
            inbox. Join our community of like-minded individuals and never miss
            out on valuable insights.
          </>
        }
      />
      <SectionContainer>
        <Col xs="11" sm="8" lg="4">
          <Form
            method="post"
            action="https://mailing.astraincubator.com/subscription/form"
            className="px-1"
          >
            <input type="hidden" name="nonce" />
            <input
              id="b5d4d"
              type="hidden"
              name="l"
              value="b5d4d417-f464-4970-8c20-1b01f98632da"
            />
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name *</Form.Label>
              <Form.Control required name="name" type="text" placeholder="" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email *</Form.Label>
              <Form.Control required name="email" type="email" placeholder="" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="privacyPolicy">
              <Row xs={2}>
                <Col xs="auto">
                  <Form.Check required type="checkbox" />
                </Col>
                <Col xs="auto">
                  <Form.Label>
                    I agree to the{" "}
                    <a href="https://www.iubenda.com/privacy-policy/63271577">
                      Privacy Policy
                    </a>{" "}
                    *
                  </Form.Label>
                </Col>
              </Row>
            </Form.Group>
            <Button variant="primary" type="submit">
              Subscribe
            </Button>
          </Form>
        </Col>
      </SectionContainer>
    </div>
  );
};

export default Newsletter;
