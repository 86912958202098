import style from "./footer.module.css";
import CenterLead from "../center-lead/center-lead.component";
import SocialIcon from "../social-icon/social-icon.component";
import logo from "../../assets/images/brand/logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className={style.main}>
      <div className={style.grid}>
        <div className={style.astraBox}>
          <h4 className={style.astraTitle}> Astra Incubator</h4>
          <p className={style.astra}>
            Astra is the first Italian incubation program designed to connect
            aspiring innovators and build multidisciplinary teams. Our purpose
            is to work with brilliant student founders and recent graduates and
            bring new Italian startups into the world.
          </p>
          <div className={style.navLink}>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/speakers">Speakers & Mentors</Link>
            <Link to="/startups">Startups</Link>
            <Link to="/contact">Contact Us</Link>
            <Link to="https://forms.gle/xfxpeF7eX7A7hWt18">Join Us</Link>
            <Link to="https://www.iubenda.com/privacy-policy/63271577/legal">
              Privacy Policy
            </Link>
          </div>
        </div>

        <div className={style.contactBox}>
          <div className={style.contactArea}>
            <h4 className={style.astraTitle}>Contact Us</h4>
            <div className={style.iconBox}>
              <SocialIcon
                type="instagram"
                href="https://www.instagram.com/astra.incubator"
              />
              <SocialIcon
                type="facebook"
                href="https://www.facebook.com/astra.incubator"
              />
              <SocialIcon
                type="linkedin"
                href="https://www.linkedin.com/company/astra-incubator"
              />
              <SocialIcon
                type="youtube"
                href="https://www.youtube.com/channel/UCt-xvfXXstL_oFYmmSACiNg"
              />
              <SocialIcon type="mail" href="mailto:info@astraincubator.com" />
            </div>
          </div>
        </div>
      </div>

      <div className={style.astraLine}>
        <CenterLead>
          <div className={style.imageBox}>
            <img
              src={logo}
              alt="Astra Incubator Logo"
              style={{ objectFit: "contain", width: "100%" }}
            />
          </div>
        </CenterLead>
      </div>
    </footer>
  );
}

export default Footer;
