import step from "../assets/images/partners/step.png";
import notion from "../assets/images/partners/notion.png";
import miro from "../assets/images/partners/miro.png";
import aithena from "../assets/images/partners/aithena.png";
import vchub from "../assets/images/partners/vchub.png";
import iag from "../assets/images/partners/iag.png";
import tag from "../assets/images/partners/tag.png";
import eleva from "../assets/images/partners/eleva.png";
import yourdigital from "../assets/images/partners/yourdigital.png";
import creazioneimpresa from "../assets/images/partners/creazioneimpresa.png";
import techbricks from "../assets/images/partners/techbricks.png";
import abinnovationconsulting from "../assets/images/partners/abinnovationconsulting.png";
import dynamolab from "../assets/images/partners/dynamolab.png";
import ltf from "../assets/images/partners/ltf.png";
import bsre from "../assets/images/partners/bsre.png";
import eclubit from "../assets/images/partners/eclubit.png";
import beapolimi from "../assets/images/partners/beapolimi.png";
import hsa from "../assets/images/partners/hsa.png";
import bspe from "../assets/images/partners/bspe.png";
import elsapavia from "../assets/images/partners/elsapavia.png";
import rotaract from "../assets/images/partners/rotaract.png";
import jecomm from "../assets/images/partners/jecomm.png";
import eclubpavia from "../assets/images/partners/eclubpavia.png";
import eclubpolimi from "../assets/images/partners/eclubpolimi.png";
import jelu from "../assets/images/partners/jelu.png";
import jetop from "../assets/images/partners/jetop.png";
import jeve from "../assets/images/partners/jeve.png";
import jemsa from "../assets/images/partners/jemsa.png";

type Partner = {
  name: string;
  href?: string;
  image: string;
};

const partners: {
  international: Partner[];
  national: Partner[];
  campus: Partner[];
} = {
  international: [
    {
      name: "STEP",
      href: "https://step.berkeley.edu/",
      image: step,
    },
    {
      name: "Notion",
      href: "https://www.notion.so/",
      image: notion,
    },
    {
      name: "Miro",
      href: "https://miro.com/",
      image: miro,
    },
    {
      name: "Aithena",
      href: "https://aithena.ai/",
      image: aithena,
    },
  ],
  national: [
    {
      name: "VCHub",
      href: "https://www.vchub.it/",
      image: vchub,
    },
    {
      name: "Italian Angels for Growth",
      href: "https://www.italianangels.net/",
      image: iag,
    },
    {
      name: "TalentTargen",
      href: "https://talentgarden.org/",
      image: tag,
    },
    {
      name: "Eleva",
      href: "https://www.eleva.it/",
      image: eleva,
    },
    {
      name: "yourDIGITAL",
      href: "https://yourdigital.it/",
      image: yourdigital,
    },
    {
      name: "Creazione Impresa",
      href: "https://creazioneimpresa.net/",
      image: creazioneimpresa,
    },
    {
      name: "TechBricks",
      href: "https://www.techbricks.io/",
      image: techbricks,
    },
    {
      name: "AB Innovation Consulting",
      href: "https://abinnovationconsulting.com/",
      image: abinnovationconsulting,
    },
    {
      name: "DynamoLAB",
      href: "https://www.dynamo-lab.com/",
      image: dynamolab,
    },
    {
      name: "LeadTheFuture",
      href: "https://leadthefuture.tech/",
      image: ltf,
    },
  ],
  campus: [
    {
      name: "JELU",
      href: "https://www.jelu.it/",
      image: jelu,
    },
    {
      name: "JETOP",
      href: "https://jetop.com/",
      image: jetop,
    },
    {
      name: "JEVE",
      href: "https://jeve.it/",
      image: jeve,
    },
    {
      name: "JEMSA",
      href: "https://www.linkedin.com/company/jemsa-consulting/",
      image: jemsa,
    },
    {
      name: "BSRE",
      href: "https://www.bsrealestate.it/",
      image: bsre,
    },
    {
      name: "Entrepreneurship Club",
      href: "https://entrepreneurshipclub.it/",
      image: eclubit,
    },
    {
      name: "Biomedical Engineering Association",
      href: "https://beapolimi.it/",
      image: beapolimi,
    },
    {
      name: "HSA",
      href: "https://it-it.facebook.com/HubofStudentActivites/",
      image: hsa,
    },
    {
      name: "BSPE Club",
      href: "https://bspeclubcom.wordpress.com/",
      image: bspe,
    },
    {
      name: "Elsa Pavia",
      href: "https://www.elsa-italy.org/pavia/",
      image: elsapavia,
    },
    {
      name: "Rotaract Distretto 2041",
      href: "http://www.rotaract2041.com/",
      image: rotaract,
    },
    {
      name: "JeCoMM",
      href: "https://jecomm.it/",
      image: jecomm,
    },
    {
      name: "Entrepreneurship Club Pavia",
      href: "https://www.eclubpavia.it/",
      image: eclubpavia,
    },
    {
      name: "Entrepreneurship Club PoliMI",
      href: "https://www.eclubpolimi.it/",
      image: eclubpolimi,
    },
  ],
};

export default partners;
export type { Partner };
