import { Image } from "react-bootstrap";
import styled from "styled-components";

type CustomImageProps = {
  variant: "lg" | "md" | "sm";
};

/**
 * Image for the PartnerImage component
 */
const CustomImage = styled(Image)<CustomImageProps>`
  max-height: ${({ variant }) =>
    variant === "lg" ? "130" : variant === "sm" ? "60" : "80"}px;
  max-width: 100%;
  opacity: 0.7;
  transition: all 0.2s;
  filter: grayscale(100);

  &:hover {
    opacity: 1;
  }
`;

export { CustomImage };
