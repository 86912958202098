import {
  IconBox,
  StyledFacebook,
  StyledInstagram,
  StyledLinkedIn,
  StyledMail,
  StyledYouTube,
} from "./social-icon.styles";

type Icon = "instagram" | "linkedin" | "youtube" | "facebook" | "mail";
type Props = {
  type: Icon;
  href: string;
  className?: string;
};

function getIcon(type: Icon) {
  switch (type) {
    case "facebook":
      return StyledFacebook;
    case "instagram":
      return StyledInstagram;
    case "linkedin":
      return StyledLinkedIn;
    case "mail":
      return StyledMail;
    case "youtube":
      return StyledYouTube;
  }
}

const SocialIcon: React.FC<Props> = ({ type, href, className }) => {
  const Icon = getIcon(type);
  return (
    <IconBox href={href} target="_blank">
      <Icon weight="fill" size={35} className={className} />
    </IconBox>
  );
};

export default SocialIcon;
