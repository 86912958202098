import { css } from "styled-components";

const colors = {
  dark: "#212428",
  primary: "#cf4b08",
  light: "rgba(255, 241, 219, 1)",
};

const white = css`
  color: #ffffff;
`;

const uppercase = css`
  text-transform: uppercase;
`;

export { white, uppercase, colors };
