import {
  Lightbulb,
  PersonSimpleRun,
  RocketLaunch,
} from "@phosphor-icons/react";
import SectionContainer from "../../../components/section-container/section-container.component";
import LookingForTile from "./looking-for-tile/looking-for-tile.component";
import { Row } from "react-bootstrap";
import { LookingForTileContainer } from "./looking-for-tile/looking-for-tile.styles";
import { useMediaQuery } from "react-responsive";
import { device } from "../../../utils/device";

type Props = {};

const lookingForData = [
  {
    title: "Aspiring innovators",
    description: `We're looking for dreamers who want to get involved. We don't care where you studied or what's on your resume:
    just bring bold ideas and will to roll up your sleeves`,
    icon: PersonSimpleRun,
  },
  {
    title: "With or without an idea, with or without a team",
    description: `You can apply as a team with an idea or as an individual looking for teammates, or you can join one of our teams working in-house on multiple MVPs`,
    icon: Lightbulb,
  },
  {
    title: "To build innovative ideas",
    description: `We will work with you to bring your ideas to the market and pitch to potential investors`,
    icon: RocketLaunch,
  },
];

const LookingFor: React.FC<Props> = () => {
  const lookingForTiles = lookingForData.map((e, i) => (
    <LookingForTile
      position={i % 2 === 0 ? "right" : "left"}
      data={e}
      key={i}
    />
  ));

  const sm = useMediaQuery({ query: device.sm });
  return (
    <SectionContainer variant="primary" fluid={sm ? "all" : "horizontal"}>
      <Row xs={1} sm={2} className="w-100">
        <LookingForTileContainer position="left">
          <h1 className="display-4">Who are we looking for?</h1>
        </LookingForTileContainer>
        {lookingForTiles}
      </Row>
    </SectionContainer>
  );
};

export default LookingFor;
