import GridDoubleSquare from "../../../../components/grid-double-square/grid-double-square.component";
import { Service } from "../../../../data/services";
import ComponentProps from "../../../../utils/ComponentProps";
import { ServiceText } from "./service-tile.styles";

type Props = ComponentProps<{
  data: Service;
  position: "left" | "right";
}>;

/**
 * A tile for a service.
 */
const ServiceTile: React.FC<Props> = ({ data, position }) => (
  <GridDoubleSquare
    image={data.image}
    position={position}
    contentOptions={{ backgroundColor: "", hoverBackgroundColor: "" }}
  >
    <h3>{data.name}</h3>
    <ServiceText>{data.description}</ServiceText>
  </GridDoubleSquare>
);

export default ServiceTile;
