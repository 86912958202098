type FAQ = {
  question: string;
  answer: string;
};
const faqs: FAQ[] = [
  {
    question: "What's the cost of the program?",
    answer: "Astra is entirely free for the admitted teams.",
  },
  {
    question: "I don't have an idea for a business. Can I still apply?",
    answer:
      "Astra is always looking for dreamers with diverse skills. If you don't have a startup idea, we will match you with a team that is looking for someone with your skills.",
  },
  {
    question: "What is the commitment required?",
    answer:
      "Building a startup is far from being an easy task. We look for participants willing to take Astra seriously. Launching a successful startup requires a considerable time investment.",
  },
  {
    question: "What type of skills do I need?",
    answer:
      "The desire to learn Lean Startup concepts and a genuine passion to build innovative business ideas are the only requirements for Astra. We welcome candidates with all types of backgrounds.",
  },
];

export default faqs;
export type { FAQ };
