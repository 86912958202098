import ButtonLink from "../button-link/button-link.component";
import { CTAContainer } from "./cta-section.styles";

type Props = {
  text: string;
  btnText: string;
  btnHref: string;
};

const CTASection: React.FC<Props> = ({ text, btnText, btnHref }) => (
  <CTAContainer>
    <p className="lead">{text}</p>
    <ButtonLink className="mt-5" size="lg" href={btnHref}>
      {btnText}
    </ButtonLink>
  </CTAContainer>
);

export default CTASection;
