import PartnerImage from "./partner-image/partner-image.component";
import { PartnerRow } from "./partners.styles";
import { Partner } from "../../../data/partners";
import SectionContainer from "../../../components/section-container/section-container.component";
import SectionLead from "../../../components/section-lead/section-lead.component";

type Props = {
  partners: {
    international: Partner[];
    national: Partner[];
    campus: Partner[];
  };
};

/**
 * Homepage partners section
 */
const Partners: React.FC<Props> = ({ partners }) => {
  const internationalPartners = partners.international.map((e, i: number) => (
    <PartnerImage data={e} variant="lg" key={i} />
  ));

  const nationalPartners = partners.national.map((e, i: number) => (
    <PartnerImage data={e} />
  ));

  const campusPartners = partners.campus.map((e, i: number) => (
    <PartnerImage data={e} variant="sm" />
  ));

  return (
    <SectionContainer variant="dark" className="px-4">
      <SectionLead
        title="Meet our awesome partners."
        text={
          "Our wide-ranging network of organizations and associations is the driving force behind our mission to foster a vibrant community for future Italian entrepreneurs."
        }
      />
      <h4 className="mb-4 w-100">International</h4>
      <PartnerRow
        className="justify-content-center mb-4 align-items-center"
        xs={1}
        sm={2}
        lg={3}
      >
        {internationalPartners}
      </PartnerRow>

      <h4 className="mb-4 w-100">National</h4>
      <PartnerRow
        className="justify-content-center mb-4 align-items-center"
        xs={1}
        sm={2}
        lg={4}
      >
        {nationalPartners}
      </PartnerRow>

      <h4 className="mb-4 w-100">Campus</h4>
      <PartnerRow
        className="justify-content-center mb-4 align-items-center"
        xs={1}
        sm={2}
        md={4}
        lg={5}
      >
        {campusPartners}
      </PartnerRow>
    </SectionContainer>
  );
};

export default Partners;
