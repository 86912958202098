import HeroTitle from "../../components/hero-title/hero-title.component";
import TitleSection from "../../components/title-section/title-section.component";

import imgHero from "../../assets/images/heros/contact.jpg";
import { Row } from "react-bootstrap";
import FeatureElement from "../../components/feature-element/feature-element.component";
import {
  EnvelopeSimple,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import SectionContainer from "../../components/section-container/section-container.component";
import SectionLead from "../../components/section-lead/section-lead.component";
import ButtonLink from "../../components/button-link/button-link.component";

function Contact() {
  return (
    <div>
      <HeroTitle imgSrc={imgHero}>
        Our
        <br />
        Contacts
      </HeroTitle>
      <TitleSection
        title="Contact Us"
        text={
          <>
            We&apos;re always happy to hear your thoughts and answer your
            questions! Send us an email or a message to get in touch with us.
          </>
        }
      />
      <SectionContainer fluid="vertical">
        <Row className="w-100" xs={1} md={3}>
          <FeatureElement
            text="info@astraincubator.com"
            icon={EnvelopeSimple}
            href="mailto:info@astraincubator.com"
          />
          <FeatureElement
            text="astra.incubator"
            icon={InstagramLogo}
            href="https://www.instagram.com/astra.incubator"
          />
          <FeatureElement
            text="astra.incubator"
            icon={FacebookLogo}
            href="https://www.facebook.com/astra.incubator"
          />
          <FeatureElement
            text="Astra Incubator"
            icon={LinkedinLogo}
            href="https://www.linkedin.com/company/astra-incubator"
          />
          <FeatureElement
            text="Astra Incubator"
            icon={YoutubeLogo}
            href="https://www.youtube.com/channel/UCt-xvfXXstL_oFYmmSACiNg"
          />
        </Row>
      </SectionContainer>
      <SectionContainer variant="light">
        <SectionLead
          title={<>Volunteer with us</>}
          text={
            <>
              We&apos;re always looking for motivated people and organizations
              who want to contribute to our vision. Volunteer with Astra and
              help us shape the future of Italian innovation.
            </>
          }
        />
        <ButtonLink
          className="mb-5"
          size="lg"
          href="https://forms.gle/xfxpeF7eX7A7hWt18"
        >
          Join us
        </ButtonLink>
        <FeatureElement
          text="apply@astraincubator.com"
          icon={EnvelopeSimple}
          href="mailto:apply@astraincubator.com"
        />
      </SectionContainer>
    </div>
  );
}

export default Contact;
