import About from "../routes/about/about.component";
import Contact from "../routes/contact/contact.component";
import Home from "../routes/home/home.component";
import Speakers from "../routes/speakers/speakers.component";
import Startups from "../routes/startups/startups.component";

type Route = {
  name: string;
  url: string;
  component?: React.FC;
};

const routes: Route[] = [
  { name: "Home", url: "/", component: Home },
  {
    name: "Apply",
    url: "https://apply.astraincubator.com",
  },
  {
    name: "About",
    url: "/about",
    component: About,
  },
  {
    name: "Speakers & Mentors",
    url: "/speakers",
    component: Speakers,
  },
  {
    name: "Startups",
    url: "/startups",
    component: Startups,
  },
  {
    name: "Contact Us",
    url: "/contact",
    component: Contact,
  },
];

export default routes;
export type { Route };
