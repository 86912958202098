import { Row } from "react-bootstrap";
import ComponentProps from "../../utils/ComponentProps";
import StatsElement, { Stat } from "../stats-element/stats-element.component";

type Props = ComponentProps<{
  stat1: Stat;
  stat2: Stat;
  stat3: Stat;
}>;

const StatsRow: React.FC<Props> = ({ stat1, stat2, stat3 }) => (
  <Row xs={1} sm={3} className="py-5 justify-content-center m-0 w-100">
    {[stat1, stat2, stat3].map((e, i) => (
      <StatsElement className="pb-5" key={i} stat={e} />
    ))}
  </Row>
);

export default StatsRow;
