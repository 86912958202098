import { motion } from "framer-motion";
import styled from "styled-components";

/**
 * Animated button container for the startup tile
 */
const StartupButtonContainer = styled(motion.div)`
  position: absolute;
  bottom: 20px;
`;

export { StartupButtonContainer };
