import styled from "styled-components";
import { colors } from "../../utils/styles";

/**
 * Box for duotone icon with primary color.
 */
const IconBoxPrimary = styled.p`
  [opacity="0.2"] {
    fill: ${colors.primary};
  }
`;

export default IconBoxPrimary;
