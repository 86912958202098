import styled from "styled-components";
import Hero from "../hero/hero.component";
import { motion } from "framer-motion";

type Props = {
  imgSrc: string;
  children: string | React.ReactNode;
};

/**
 * Hero component with custome height
 */
const CustomHero = styled(Hero)`
  height: 80vh;
`;

const HeroTitle: React.FC<Props> = ({ imgSrc, children }) => (
  <CustomHero imgSrc={imgSrc} vAlign="center">
    <motion.h1
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: "easeInOut",
        duration: 1,
        opacity: {
          delay: 0.1,
          duration: 1,
        },
      }}
      className="display-1"
    >
      {children}
    </motion.h1>
  </CustomHero>
);

export default HeroTitle;
