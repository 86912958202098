import { useState } from "react";
import { Startup } from "../../../data/startups";
import ComponentProps from "../../../utils/ComponentProps";
import {
  StartupContainer,
  StartupImageContainer,
  StartupInfo,
  StartupLink,
  StartupTile1,
  StartupTile2,
  StartupTile3,
  StartupCategory,
  StartupTile4,
} from "./startup.tile.styles";
import { AnimatePresence } from "framer-motion";
import { Image } from "react-bootstrap";
import StartupInfoButton from "./startup-plus-button/startup-plus-button.component";

type Props = ComponentProps<{
  data: Startup;
  variant: number;
}>;

/**
 * get tile component based on the provided variant number
 */
function getTile(variant: number): React.ElementType {
  switch (variant) {
    case 1:
      return StartupTile1;
    case 2:
      return StartupTile2;
    case 3:
      return StartupTile3;
    default:
      return StartupTile4;
  }
}

/**
 * A square tile for a startup in the startups page.
 * Accepts a startup data and a variant number, indicating the background color the tile should have
 */
const StartupTile: React.FC<Props> = ({ data, variant }) => {
  const [detail, setDetail] = useState(false);
  const [hover, setHover] = useState(false);
  const Tile = getTile(variant);

  const onClickHandler = () => setDetail(!detail);
  const onMouseOverHandler = () => setHover(true);
  const onMouseLeaveHandler = () => setHover(false);

  return (
    <Tile
      onClick={onClickHandler}
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <StartupContainer>
        <AnimatePresence>
          <StartupCategory
            key={`${data.name}-category`}
            animate={{ opacity: detail ? 0 : 1 }}
          >
            {data.category}
          </StartupCategory>

          <StartupImageContainer key={`${data.name}-image`} layout>
            <Image src={data.image}></Image>
          </StartupImageContainer>
          {detail && (
            <StartupInfo
              key={`${data.name}-info`}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <p>{data.description}</p>
              {data.website && (
                <StartupLink
                  href={data.website}
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn More
                </StartupLink>
              )}
            </StartupInfo>
          )}
          <StartupInfoButton
            variant={detail ? "close" : "more"}
            hover={hover}
          />
        </AnimatePresence>
      </StartupContainer>
    </Tile>
  );
};

export default StartupTile;
