import styled from "styled-components";

type HeroBoxProps = {
  bgUrl: string;
};

/**
 * The outer box for the hero component
 */
const HeroBox = styled.div<HeroBoxProps>`
  position: relative;
  background-color: black;
  background-image: url(${({ bgUrl }) => bgUrl});
  background-size: cover;
  background-position: center;
  min-height: 50vh;
`;

type HeroContentProps = {
  hAlign: "start" | "center" | "end";
  vAlign: "start" | "center" | "end";
};

/**
 * The content box for the hero component. Includes content alignment features
 */
const HeroContent = styled.div<HeroContentProps>`
  height: 100%;
  width: 100%;
  color: white;
  backdrop-filter: brightness(50%);
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.vAlign};
  align-items: ${(props) => props.hAlign};
`;

export { HeroBox, HeroContent };
