import { useState } from "react";
import { Navbar, Nav, Container, Offcanvas } from "react-bootstrap";
import logo from "../../assets/images/brand/logo.png";
import {
  NavbarLogo,
  StyledNavbar,
  StyledOffcanvasBody,
  StyledOffcanvasHeader,
  StyledOffcanvas,
} from "./navbar.styles";
import { useLocation } from "react-router-dom";
import NavbarLink from "../navbar-link/navbar-link.component";
import routes from "../../data/routes";

const AstraNavbar: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [open, setOpen] = useState(false);

  const openOffcanvas = () => setOpen(true);
  const closeOffcanvas = () => setOpen(false);

  return (
    <StyledNavbar data-bs-theme="dark" expand="md" expanded={open}>
      <Container fluid>
        <Navbar.Brand href="/">
          <NavbarLogo src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle onClick={openOffcanvas} />
        <StyledOffcanvas
          id={`navbarOffcanvas`}
          aria-labelledby={`navbarOffcanvasLabel`}
          placement="end"
        >
          <StyledOffcanvasHeader
            className="text-bg-dark"
            closeButton
            closeVariant="white"
            onHide={closeOffcanvas}
          >
            <Offcanvas.Title id={`navbarOffcanvasLabel`}>
              <NavbarLogo src={logo} />
            </Offcanvas.Title>
          </StyledOffcanvasHeader>
          <StyledOffcanvasBody>
            <Nav className="justify-content-end flex-grow-1 column-gap-3 pe-3">
              {routes.map((e) => (
                <NavbarLink
                  key={e.url}
                  active={pathname === e.url}
                  href={e.url}
                  onClick={closeOffcanvas}
                >
                  {e.name}
                </NavbarLink>
              ))}
            </Nav>
          </StyledOffcanvasBody>
        </StyledOffcanvas>
      </Container>
    </StyledNavbar>
  );
};

export default AstraNavbar;
