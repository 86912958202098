import styled from "styled-components";
import { colors, white } from "../../utils/styles";

const BannerBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  flex-wrap: wrap;

  & > * {
    text-align: center;
    font-weight: 400;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
  }

  & > b {
    font-weight: 700;
  }
`;
const BannerPrimary = styled(BannerBase)`
  background-color: ${colors.primary};
  ${white}
`;
const BannerLight = styled(BannerBase)`
  background-color: ${colors.light};
`;
const BannerDark = styled(BannerBase)`
  background-color: ${colors.light};
  ${white}
`;

export { BannerBase, BannerDark, BannerLight, BannerPrimary };
