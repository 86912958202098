import albertodemartini from "../assets/images/speakers/albertodemartini.jpg";
import antoniaverna from "../assets/images/speakers/antoniaverna.jpg";
import antoniocostaura from "../assets/images/speakers/antoniocostaura.jpeg";
import davidedattoli from "../assets/images/speakers/davidedattoli.jpeg";
import domenicolaudonia from "../assets/images/speakers/domenicolaudonia.jpg";
import lauraloprieno from "../assets/images/speakers/lauraloprieno.jpeg";
import manuelemonti from "../assets/images/speakers/manuelemonti.jpg";
import manuelguerrera from "../assets/images/speakers/manuelguerrera.jpeg";
import salvatoreviola from "../assets/images/speakers/salvatoreviola.jpeg";
import claudiodellera from "../assets/images/speakers/claudiodellera.jpg";
import cristinacrupi from "../assets/images/speakers/cristinacrupi.jpg";
import enricopandian from "../assets/images/speakers/enricopandian.jpg";
import federicocapeci from "../assets/images/speakers/federicocapeci.jpg";
import giorgiodellerba from "../assets/images/speakers/giorgiodellerba.jpg";
import giulianonoci from "../assets/images/speakers/giulianonoci.jpg";
import jacoposebastio from "../assets/images/speakers/jacoposebastio.jpg";
import lorenzamorandini from "../assets/images/speakers/lorenzamorandini.jpg";
import lucamenato from "../assets/images/speakers/lucamenato.jpg";
import mariaritacostanza from "../assets/images/speakers/mariaritacostanza.jpg";
import mauriziolacava from "../assets/images/speakers/mauriziolacava.jpeg";
import niccolosanarico from "../assets/images/speakers/niccolosanarico.jpg";
import tobiadeangelis from "../assets/images/speakers/tobiadeangelis.jpeg";
import vincenzocosenza from "../assets/images/speakers/vincenzocosenza.jpg";

type Speaker = {
  name: string;
  role: string;
  linkedin?: string;
  image: string;
};

const speakers: Speaker[] = [
  {
    name: "Alberto de Martini",
    role: "CEO @ Conic",
    linkedin: "https://www.linkedin.com/in/alberto-de-martini-5a96b87/",
    image: albertodemartini,
  },
  {
    name: "Davide Dattoli",
    role: "CEO @ TalentGarden",
    linkedin: "https://www.linkedin.com/in/davidedattoli/",
    image: davidedattoli,
  },
  {
    name: "Domenico Laudonia",
    role: "Venture Partner @ Indaco SGR",
    linkedin: "https://www.linkedin.com/in/ibfdome/",
    image: domenicolaudonia,
  },
  {
    name: "Manuel Guerrera",
    role: "Lean Startup Coach",
    linkedin: "https://www.linkedin.com/in/manuelguerrera/",
    image: manuelguerrera,
  },
  {
    name: "Antonio Costaura",
    role: "CFO @ BrumBrum",
    linkedin: "https://www.linkedin.com/in/costaura/",
    image: antoniocostaura,
  },
  {
    name: "Manuele Monti",
    role: "CEO & Co-Founder @ TechBricks",
    linkedin: "https://www.linkedin.com/in/manuelemonti/",
    image: manuelemonti,
  },
  {
    name: "Laura Loprieno",
    role: "CEO & Digital Marketing Strategist @ Dynamo",
    linkedin: "https://www.linkedin.com/in/lauraloprieno/",
    image: lauraloprieno,
  },
  {
    name: "Salvatore Viola",
    role: "Business Developer @ Dynamo, Direttore @ StartUP News",
    linkedin: "https://www.linkedin.com/in/salvatore-viola/",
    image: salvatoreviola,
  },
  {
    name: "Antonia Verna",
    role: "Partner @ Portolano Cavallo",
    linkedin: "https://www.linkedin.com/in/antonia-verna-a1a77a/",
    image: antoniaverna,
  },
  {
    name: "Claudio dell'Era",
    role: "Associate Professor of Design Strategy @ PoliMi",
    linkedin: "https://www.linkedin.com/in/claudio-dell-era-798668/",
    image: claudiodellera,
  },
  {
    name: "Cristina Crupi",
    role: 'PoliHub Legal Expert, Author "Il Codice delle Startup',
    linkedin: "https://www.linkedin.com/in/cristina-crupi-61b0a737/",
    image: cristinacrupi,
  },
  {
    name: "Enrico Pandian",
    role: "Founder @ Startup Gym, FrescoFrigo",
    linkedin: "https://www.linkedin.com/in/enricopandian/",
    image: enricopandian,
  },
  {
    name: "Federico Capeci",
    role: "CEO @ Kantar Italy",
    linkedin: "https://www.linkedin.com/in/federicocapeci/",
    image: federicocapeci,
  },
  {
    name: "Giorgio dell'Erba",
    role: "Founder @ Fleep Technologies",
    linkedin: "https://www.linkedin.com/in/gdellerba/",
    image: giorgiodellerba,
  },
  {
    name: "Giuliano Noci",
    role: "Vice Rector for China @ PoliMi",
    linkedin: "https://www.linkedin.com/in/gnoci/",
    image: giulianonoci,
  },
  {
    name: "Jacopo Sebastio",
    role: "CEO @ Velasca",
    linkedin: "https://www.linkedin.com/in/jacopo-sebastio-6213bb7/",
    image: jacoposebastio,
  },
  {
    name: "Lorenza Morandini",
    role: "Managing Director @ Angels4Women",
    linkedin: "https://www.linkedin.com/in/lorenzamorandini/",
    image: lorenzamorandini,
  },
  {
    name: "Luca Menato",
    role: "CEO & Co-Founder @ Sommelier Wine Box",
    linkedin: "https://www.linkedin.com/in/luca-menato-8a7b4257/",
    image: lucamenato,
  },
  {
    name: "Mariarita Costanza",
    role: "CEO & Co-Founder @ Everywhere TEW",
    linkedin: "https://www.linkedin.com/in/mariarita-costanza-414b5715/",
    image: mariaritacostanza,
  },
  {
    name: "Maurizio La Cava",
    role: "Pitching expert @ PoliMi, MLC Presentation Design",
    linkedin: "https://www.linkedin.com/in/mauriziolacava/",
    image: mauriziolacava,
  },
  {
    name: "Niccolò Sanarico",
    role: "Investment Manager @ Primo Miglio",
    linkedin: "https://www.linkedin.com/in/niccolosanarico/",
    image: niccolosanarico,
  },
  {
    name: "Tobia de Angelis",
    role: "CEO @ Strive School",
    linkedin: "https://www.linkedin.com/in/tobiadeangelis/",
    image: tobiadeangelis,
  },
  {
    name: "Vincenzo Cosenza",
    role: "CMO @ Buzzoole",
    linkedin: "https://www.linkedin.com/in/vincos/",
    image: vincenzocosenza,
  },
];

export default speakers;
export type { Speaker };
