import React from "react";
import { Service } from "../../../data/services";
import { useMediaQuery } from "react-responsive";
import { device } from "../../../utils/device";
import SectionContainer from "../../../components/section-container/section-container.component";
import GridContainer from "../../../components/grid-container/grid-container.component";
import ServiceTile from "./service-tile/service-tile.component";
import ComponentProps from "../../../utils/ComponentProps";
import SectionLead from "../../../components/section-lead/section-lead.component";
import StatsRow from "../../../components/stats-row/stats-row.component";

type Props = ComponentProps<{
  services: Service[];
}>;

const Services: React.FC<Props> = ({ services, ...props }) => {
  const md = useMediaQuery({ query: device.md });
  const servicesComponents = services.map((e: any, i: number) => (
    <ServiceTile
      position={i % 2 === 1 && md ? "left" : "right"}
      data={e}
      key={i}
    />
  ));
  return (
    <SectionContainer fluid={"vertical"} {...props}>
      <SectionLead
        title="Plus, you will access an unparalleled set of
opportunities."
      />
      <StatsRow
        stat1={{
          number: 20,
          postNumber: "h+",
          text: "of exclusive online learning content",
        }}
        stat2={{
          number: 100,
          postNumber: "h+",
          text: "of dedicated coaching",
        }}
        stat3={{
          number: 20,
          postNumber: "+",
          text: "professionals available for advisory",
        }}
      />
      <GridContainer xs={1} md={2}>
        {servicesComponents}
      </GridContainer>
    </SectionContainer>
  );
};
export default Services;
