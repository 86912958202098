import HighlightedText from "../../components/highlighted-text/highlighted-text.component";
import { HomeHero, HomeHeroButtonBox, HomeHeroTextBox } from "./home.styles";

import imgHero from "../../assets/images/heros/home.jpg";
import Partners from "./partners/partners.component";
import FAQs from "./faqs/faqs.component";

import partnersData from "../../data/partners";
import faqsData from "../../data/faqs";
import servicesData from "../../data/services";
import timelineData from "../../data/timeline";
import ButtonLink from "../../components/button-link/button-link.component";
import Services from "./services/services.component";
import Typed from "typed.js";
import { useEffect, useRef } from "react";
import AnimatedDownIcon from "../../components/animated-down-icon/animated-down-icon.component";
import Timeline from "./timeline/timeline.component";
import LookingFor from "./looking-for/looking-for.component";
import SectionContainer from "../../components/section-container/section-container.component";
import TitleSection from "../../components/title-section/title-section.component";
import { Row } from "react-bootstrap";
import FeatureElement from "../../components/feature-element/feature-element.component";
import { Hammer, NotePencil, UsersThree } from "@phosphor-icons/react";

const Home: React.FC = () => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["innovative ideas", "strong teams", "early stage startups"],
      showCursor: true,
      typeSpeed: 40,
      backSpeed: 40,
      backDelay: 1000,
      onComplete: (self) => setTimeout(() => (self.cursor.hidden = true), 1000),
    });
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <>
      <HomeHero imgSrc={imgHero} hAlign="start" vAlign="center">
        <HomeHeroTextBox>
          <h1 className="display-1">
            Where <span ref={el}></span>
            <br />
            take shape.
          </h1>
        </HomeHeroTextBox>
        <HomeHeroButtonBox className="pt-5">
          <ButtonLink
            href="https://apply.astraincubator.com"
            variant="outline-light"
            size="lg"
            className={"m-2"}
          >
            Apply now for Batch IV
          </ButtonLink>
          <ButtonLink
            href="https://forms.gle/xfxpeF7eX7A7hWt18"
            variant="outline-light"
            size="lg"
            className={"m-2"}
          >
            Join our team
          </ButtonLink>
        </HomeHeroButtonBox>
        <AnimatedDownIcon />
      </HomeHero>
      <TitleSection
        title={<>We empower entrepreneurs. We do it in Italy.</>}
        text={
          <>
            Astra is the first Italian incubation program designed to connect
            aspiring innovators and build multidisciplinary teams. Our purpose
            is to form inspiring founding teams with brilliant student founders
            and recent graduates to
            <HighlightedText>
              {" "}
              bring new Italian startups into the world
            </HighlightedText>
            .
          </>
        }
      ></TitleSection>
      <SectionContainer fluid="vertical">
        <Row className="w-100" xs={1} md={3}>
          <FeatureElement
            text="If you have an idea and an established team, or you are just interested in startups, Astra is the right place."
            title="Apply"
            icon={NotePencil}
          />
          <FeatureElement
            text="We will guide you through a 10-week incubation program to apply techniques and strategies which have made Silicon Valley the world's first innovation hub."
            title="Build an MVP"
            icon={Hammer}
          />
          <FeatureElement
            text="At the end of your journey, you'll have the amazing opportunity to showcase your idea to the italian most relevant investors, angels and accelerators."
            title="Pitch to investors"
            icon={UsersThree}
          />
        </Row>
      </SectionContainer>
      <LookingFor />
      <SectionContainer variant="light" fluid="horizontal">
        <Timeline timeline={timelineData} />
        <Services services={servicesData} />
      </SectionContainer>
      <Partners partners={partnersData} />
      <FAQs faqs={faqsData} />
    </>
  );
};

export default Home;
