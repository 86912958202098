import francescodipierro from "../assets/images/team/francescodipierro.jpeg";
import matteoagnoletto from "../assets/images/team/matteoagnoletto.jpeg";
import kevinstinat from "../assets/images/team/kevinstinat.jpg";
import mattiatalacci from "../assets/images/team/mattiatalacci.jpg";

type Member = {
  name: string;
  role: string;
  description?: string;
  linkedin?: string;
  facebook?: string;
  instagram?: string;
  image: string;
};

const team: Member[] = [
  {
    name: "Francesco Dipierro",
    role: "Director",
    description:
      "In the process of discovering the best pizzerias and cocktail bars in the world. When not reading about politics, you will find Francesco watching one of its favourite italian neorealistic movies.",
    linkedin: "https://www.linkedin.com/in/francescodipierro/",
    image: francescodipierro,
  },
  {
    name: "Matteo Agnoletto",
    role: "VP of Engineering",
    description:
      "A piano, a computer and a cup of coffee are his perfect mix. Free time videomaker and musician, he loves to discover innovative technologies and see the world of tomorrow through his own eyes.",
    linkedin: "https://www.linkedin.com/in/epmatt/",
    image: matteoagnoletto,
  },
  {
    name: "Kevin Stinat",
    role: "Campus Relations",
    linkedin: "https://www.linkedin.com/in/kevinstinat",
    image: kevinstinat,
  },
  {
    name: "Mattia Talacci",
    role: "ML Engineer",
    linkedin: "https://www.linkedin.com/in/mattia-talacci/",
    image: mattiatalacci,
  },
];

export default team;
export type { Member };
