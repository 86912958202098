import { Icon } from "@phosphor-icons/react";
import ComponentProps from "../../utils/ComponentProps";
import {
  FeatureElementContainer,
  FeatureText,
  FeatureTitle,
  FeatureLink,
} from "./feature-element.styles";
import IconBoxPrimary from "../icon-box-primary/icon-box-primary.component";

type Props = ComponentProps<{
  title?: string;
  text: string;
  icon: Icon;
  href?: string;
}>;

/**
 * An element for an overview of a feature. Must include an icon, optionally a title.
 * If the href is provided, the entire container acts as a link. Hovering the container will trigger an animation.
 */
const FeatureElement: React.FC<Props> = ({ title, text, icon, href }) => {
  const IconComponent = icon;
  const content = (
    <>
      <IconBoxPrimary>
        <IconComponent size={48} weight="duotone" />
      </IconBoxPrimary>
      {title && <FeatureTitle>{title}</FeatureTitle>}
      <FeatureText>{text}</FeatureText>
    </>
  );
  return (
    <FeatureElementContainer>
      {href ? <FeatureLink href={href}>{content}</FeatureLink> : content}
    </FeatureElementContainer>
  );
};

export default FeatureElement;
