import CountUp from "react-countup";
import ComponentProps from "../../utils/ComponentProps";
import { StatText } from "./stats-element.styles";

type Stat = {
  postNumber?: string;
  preNumber?: string;
  number: number;
  text: string;
};

type Props = ComponentProps<{ stat: Stat }>;

/**
 * Big number with optional text below.
 * When the number if first displayed on the screen (eg. with a user scroll), the number is animated.
 */
const StatsElement: React.FC<Props> = ({ className, stat }) => (
  <div className={`text-center ${className}`}>
    <h1>
      <CountUp
        prefix={stat.preNumber}
        suffix={stat.postNumber}
        end={stat.number}
        enableScrollSpy={true}
        scrollSpyOnce={true}
      />
    </h1>
    <StatText>{stat.text}</StatText>
  </div>
);

export default StatsElement;
export type { Stat };
