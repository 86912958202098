import { Route, Routes } from "react-router-dom";
import About from "./routes/about/about.component";
import Contact from "./routes/contact/contact.component";
import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import Speakers from "./routes/speakers/speakers.component";
import Startups from "./routes/startups/startups.component";
import Newsletter from "./routes/newsletter/newsletter.component";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="speakers" element={<Speakers />} />
        <Route path="startups" element={<Startups />} />
        <Route path="contact" element={<Contact />} />
        <Route path="newsletter" element={<Newsletter />} />
      </Route>
    </Routes>
  );
}

export default App;
