import { Link } from "react-router-dom";
import styled from "styled-components";

type CustomLinkProps = {
  active?: boolean;
};
const CustomLink = styled(Link)<CustomLinkProps>`
  position: relative;
  color: rgba(255, 255, 255, ${(props) => (props.active ? `1` : `0.80`)});
  padding-top: 10px;
  padding-bottom: 10px;

  text-decoration: unset;
  text-transform: uppercase;

  font-weight: ${(props) => (props.active ? "400" : "300")};

  &::after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: #ffffff66;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all 0.2s;
  }
  &:hover {
    ${(props) => !props.active && `color: rgba(255, 255, 255, 0.8);`}
  }
  &:hover::after {
    opacity: 1;
  }
`;

export { CustomLink };
