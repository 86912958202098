type Announcement = {
    text: string;
    cta?: { text: string; href: string };
};

const announcements: Announcement[] = [
    {
        text: "Our Team is growing - we are looking for volunteers! Help us shape the future of Italian entrepreneurship",
        cta: {
            text: "Join us",
            href: "https://forms.gle/xfxpeF7eX7A7hWt18",
        },
    },
];


export default announcements;
export type { Announcement };
