import styled from "styled-components";
import { device } from "../../../../utils/device";

type LookingForTileContainerProps = {
  position: "left" | "right";
};
const LookingForTileContainer = styled.div<LookingForTileContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 60px;

  align-items: ${({ position }) =>
    position === "left" ? "flex-start" : "flex-end"};
  text-align: ${({ position }) => position};

  @media (${device.sm}) {
    padding: 65px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  @media (${device.lg}) {
    padding: 100px;
  }

  h3 {
    margin-top: 30px;
    max-width: 600px;
  }

  p {
    margin-top: 15px;
    max-width: 450px;
    font-size: 105%;
  }
`;

export { LookingForTileContainer };
