import styled from "styled-components";
import { colors, white } from "../../utils/styles";

type SectionBaseContainerProps = {
  fluid?: "vertical" | "horizontal" | "all" | "none";
};

/**
 * Base container for a website section
 */
const SectionBaseContainer = styled.div<SectionBaseContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    !(props.fluid === "vertical" || props.fluid === "all") &&
    `
  padding-top: 200px;
  padding-bottom: 200px;

  `}

  ${(props) =>
    !(props.fluid === "horizontal" || props.fluid === "all") &&
    `
  & > * {
    max-width: 1100px;
  }
  `}
`;

const SectionContainerPrimary = styled(SectionBaseContainer)`
  background: rgb(255, 137, 76);
  background: linear-gradient(
    135deg,
    rgba(255, 137, 76, 1) 20%,
    rgba(233, 84, 8, 1) 80%
  );
  ${white}
`;
const SectionContainerLight = styled(SectionBaseContainer)`
  background: ${colors.light};
  background: linear-gradient(
    135deg,
    ${colors.light} 35%,
    rgba(254, 211, 148, 1) 80%
  );
  color: black;
`;
const SectionContainerDark = styled(SectionBaseContainer)`
  background: rgb(80, 91, 106);
  background: linear-gradient(
    135deg,
    rgba(80, 91, 106, 1) 0%,
    rgba(33, 36, 40, 1) 80%
  );
  ${white}
`;

export {
  SectionBaseContainer,
  SectionContainerPrimary,
  SectionContainerLight,
  SectionContainerDark,
};
