import { Image, Navbar, OffcanvasBody, OffcanvasHeader } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../utils/styles";

const NavbarLogo = styled(Image)`
  height: 2.7rem;
`;

const StyledNavbar = styled(Navbar)`
  position: absolute;
  top: 0px;
  z-index: 10;
  width: 100vw;

  .navbar-toggler {
    border: none;
  }
`;

const StyledOffcanvas = styled(Navbar.Offcanvas)`
  background-color: ${colors.dark};
`;

const StyledOffcanvasBody = styled(OffcanvasBody)``;

const StyledOffcanvasHeader = styled(OffcanvasHeader)``;

export {
  NavbarLogo,
  StyledNavbar,
  StyledOffcanvasBody,
  StyledOffcanvasHeader,
  StyledOffcanvas,
};
